<template>
  <div class="view-message-container">
    <h4>Просмотр сообщения</h4>
    <!--<div style="display: flex;gap: 20px">-->
    <!--<div>-->
    <!--<md-checkbox disabled v-model="exercise" value="1" class="md-secondary">Учебная</md-checkbox>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div>{{message.id}}</div>-->
    <br />

    <div>
      <md-field disabled>
        <md-textarea id="message_to_print" style="padding: 10px; width: 100%;min-height: 300px; max-height: 70vh; height: 65vh"  v-model="text" disabled :md-autogrow="true"></md-textarea>
      </md-field>
    </div>
    <div style="display: flex; ">
      <div style="display: flex; ">
        <md-button  @click="print" class="md-raised md-primary" style="margin-top: 40px; flex: 0 0 100px">Печать</md-button>
      </div>
      <div style="display: flex; justify-content: flex-end; flex: 1">,
        <md-button  @click="confirm" class="md-raised md-primary" style="margin-top: 40px;" v-if="message.type === 'output_message' || message.type === 'input_message'">Подтвердить</md-button>
        <md-button  @click="closeDialog('Отмена')" class="md-raised md-primary" style="margin-top: 40px;">Закрыть</md-button>
      </div>
    </div>

  </div>
</template>

<script>
  import {postConfirmation} from "../../api/message-controller";
  import logger from "../../utils/logger";
  import {mapActions} from 'vuex'

  export default {
    name: 'MessageForm',
    props: {
      message: Object,
    },
    data() {
      return {
        exercise: this.message.exercise,
        text: this.message.content
      }
    },
    methods: {
      ...mapActions(['showInfoAlert']),
      closeDialog() {
        this.$emit('closeViewMessageForm')
      },
      alertx(x) {
        this.showInfoAlert(x)
        this.closeDialog()
      },
      print() {
        const textarea = document.getElementById("message_to_print");
        const printWindow = window.open('', '', 'height=400,width=600');
        const printContent = printWindow.document.createElement('div');
        printContent.style.fontFamily = 'Arial';
        printContent.style.fontSize = '12px';

        // Устанавливаем текст textarea в элемент <div>
        printContent.innerHTML = textarea.value.replaceAll("\n", '<br>');

        // Добавляем элемент <div> в тело документа нового окна
        printWindow.document.body.appendChild(printContent);

        // Печатаем содержимое нового окна
        printWindow.print();

        // Закрываем новое окно
        printWindow.close();
      },
      async confirm() {
        postConfirmation({"messageId": this.message.id})
          .then(() => {
            this.alertx("Успешно подтверждено")
          })
          .catch(e => {
            logger(e)
          })
      }
    }

  }
</script>

<style scoped>
  @media screen and (max-width: 400px) {
    .view-message-container {
      min-width: 70vw
    }
  }

  @media screen and (min-width: 400px) {
    .view-message-container {
      min-width: 400px
    }
  }

  @media screen and (min-width: 800px) {
    .view-message-container {
      min-width: 750px
    }
  }

  .md-field.md-has-textarea {
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 2px;
  }

  .minvw {
    min-width: 50vw;
  }
</style>